<template>
  <div class="jobs-table">

    <!-- return comment modal -->
    <b-modal
      id="return-comment-modal"
      title="Return Comment"
      ok-variant="danger"
      ok-title="Save"
      @ok="saveComment"
    >
      <b-form-textarea v-model="returnDataEdit.return_comment" max-rows="12"></b-form-textarea>
      <p class="mt-3 mb-0">
        <b-icon variant="info" icon="info-circle-fill" class="mr-2" />
        <i>This will be <strong>visible to the customer</strong> on the original Job's page.</i>
      </p>
    </b-modal>

    <b-modal
      id="return-comment-modal-client"
      title="Return Comment"
      :ok-only="true"
      ok-title="Close"
    >
      <b-form-textarea v-model="returnDataEdit.return_comment" max-rows="12" disabled></b-form-textarea>
    </b-modal>

    <!-- Filters -->
    <div class="card p-2 pt-3 bg-light border rounded-0">
      
      <b-row>
        <!-- Left Column -->
        <b-col class="my-1" xl="6" lg="6" md="12" >
          
          <!-- Accounts -->
          <b-form-group
            v-if="userAccounts.length > 1"
            label="Account"
            label-for=""
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-1"
          >
            <multiselect 
              v-model="table_filters.account"
              trackBy="account"
              label="name"
              size="sm"
              :options="userAccounts"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              placeholder="Select to filter..."
              @input="table_filters.currentPage=1; refreshTableData()"
            ></multiselect>
          </b-form-group>

          <!-- From date -->
          <b-form-group
            label="From"
            label-for=""
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-1"
          >
            <b-form-datepicker size="sm" class="border-radius-none" v-model="table_filters.return_from_date" start-weekday="1" min="2016-01-01" :reset-button="true" locale="en" placeholder="Select to limit" @input="table_filters.currentPage=1; refreshTableData()"></b-form-datepicker>
          </b-form-group>

          <!-- To date -->
          <b-form-group
            label="To"
            label-for=""
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-1"
          >
            <b-form-datepicker size="sm" class="border-radius-none" v-model="table_filters.return_to_date" start-weekday="1" min="2016-01-01" :reset-button="true" locale="en" placeholder="Select to limit" @input="table_filters.currentPage=1; refreshTableData()"></b-form-datepicker>
          </b-form-group>

          <!-- <CountryTags /> -->
          <b-form-group
            label="Country"
            label-for="country-select"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-1"
          >
            <b-form-select
              id="country-select"
              v-model="table_filters.country"
              size="sm"
              :options="availableDeliveryCountries"
              value-field="id"
              text-field="name"
              @change="table_filters.currentPage=1; refreshTableData()"
            >
              <template #first>
                <b-form-select-option :value="null">Select to filter...</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>

        </b-col>
        
        <!-- Right Column -->
        <b-col class="my-1 align-self-end" xl="6" lg="6" md="12">

          <!-- Return Status -->
          <b-form-group
            label="Status"
            label-for=""
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-1"
          >
            <b-select
              :options="table_return_status_filters"
              v-model="table_filters.return_status" size="sm"
              value-field="id"
              text-field="text"
              @change="table_filters.currentPage=1; refreshTableData()"
            />
          </b-form-group>

          <!-- Return Actions -->
          <b-form-group
            label="Action"
            label-for=""
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-1"
          >
            <b-select
              :options="table_return_actions_filters"
              v-model="table_filters.return_action" size="sm"
              value-field="id"
              text-field="text"
              @change="table_filters.currentPage=1; refreshTableData()"
            />
          </b-form-group>

          <!-- Bounce Status -->
          <!--
          <b-form-group
            label="Bounced"
            label-for=""
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-1"
          >
            <b-select
              :options="[{ id: null, text: 'Select to filter...' }, { id: true, text: 'Show' }, { id: false, text: 'Hide' }]"
              v-model="table_filters.bounced_status" size="sm"
              value-field="id"
              text-field="text"
              @change="table_filters.currentPage=1; refreshTableData()"
            />
          </b-form-group>
          -->

          <!-- Search -->
          <b-form-group
            label="Search"
            label-for=""
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-1"
          >
            <b-input-group class="ml-auto">
              <b-input v-model="table_filters.search" size="sm" placeholder="Filter table..." debounce="400" v-on:update="table_filters.currentPage=1; refreshTableData(true)"/>
              <b-input-group-append>
                <b-button size="sm" v-on:click="refreshTableData(true)"><b-icon icon="arrow-clockwise"></b-icon></b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>

        </b-col>
      </b-row>

    </div>

    <!-- Job Table -->
    <b-table
      ref="table"
      responsive
      show-empty
      emptyText="There are no returns to show"

      :hover="false"
      :outlined="false"
      :borderless="false"

      primary-key="id"
      :items="tabledataProvider"
      :fields="table_fields"
      :tbody-tr-class="rowClass"

      :per-page="table_filters.perPage"
      :current-page="table_filters.currentPage"

      :busy.sync="table_settings.isBusy"
      :no-local-sorting="true"
      :sort-by.sync="table_filters.sortBy"
      :sort-desc.sync="table_filters.sortDesc"
    >
      <!-- custom formatted columns -->
      <template #cell(jobid)="data">
        <b-link :to="{name:'JobDetails', params: { job_id: data.item.id } }">{{data.item.jobid}}</b-link>
      </template>

      <template #cell(return_created_at)="data">
        <!-- {{ data.item.created_at | moment("Y-MM-DD HH:mm") }} -->
        <!-- {{ returnTagTime(data.item, 'marked_for_return') | moment("Y-MM-DD") }} -->
        <span v-if="data.item.return_created_at">{{ data.item.return_created_at | moment("Y-MM-DD") }}</span>
        <span v-else>No date</span>
      </template>

      <template #cell(account)="data">
        {{ userAccountName(data.item.account) }}
      </template>

      <template #cell(country)="data">
        <abbr :title="countryByCode(data.item.country)">
          {{ data.item.country || "Unknown" }}
        </abbr>
        <!-- <span v-html="flags(1)"></span> -->
      </template>

      <template #cell(return_status)="data">
        <span>{{ returnStatusName(data.item) }}</span>
        <!-- <span v-if="returnTagTime(data.item, 'return_received')">
          ({{ returnTagTime(data.item, 'return_received') | moment("Y-MM-DD") }})
        </span> -->

        <b-button
          v-if="data.item.return_comment"
          class="text-info my-0 py-0"
          variant="link"
          size="sm"
          title="View comment"
          @click="viewComment(data)"
        >
          <b-icon icon="info-circle-fill" />
        </b-button>
      </template>

      <template #cell(customer_actions)="data">
          <!-- resend -->
          <b-button
            v-if="!data.item.return_closed_at"
            class="mr-2"
            :variant="data.item.return_action == 'resend' ? 'success' : 'outline-secondary'"
            size="sm"
            :xxx-disabled="data.item.return_action == 'resend'"
            @click="markAction(data.item, 'resend')"
            title="Parcel(s) should be sent one more time to the customer (please also verify address)."
          >Send again</b-button>

          <!-- restock -->
          <b-button
            v-if="!data.item.return_closed_at"
            class="mr-2"
            :variant="data.item.return_action == 'restock' ? 'success' : 'outline-secondary'"
            size="sm"
            :xxx-disabled="data.item.return_action == 'restock'"
            @click="markAction(data.item, 'restock')"
            title="Alfredssons may take back the content (and possibly restock)."
          >Return</b-button>

          <!-- only text of choice when closed -->
          <span v-if="!!data.item.return_closed_at">{{ data.item.return_action == 'restock' ? 'Restock' : 'Resend' }}</span>

          <!-- view return comment -->
          <!-- <b-button
            :variant="data.item.return_comment ? 'outline-info' : 'outline-secondary'"
            :disabled="!data.item.return_comment"
            :title="data.item.return_comment ? 'View comment' : 'No comment registrered'"
            size="sm"
            @click="viewComment(data)"
          >
            <b-icon icon="info-circle-fill" />
          </b-button> -->

      </template>

      <template #cell(actions)="data">
        <div class="d-flex align-items-top">
          <!-- return -->
          <b-button
            class="mr-2"
            :variant="!returnTagTime(data.item, 'return_received') ? 'outline-success' : 'success'"
            size="sm"
            :title="returnTagTime(data.item, 'return_received') ? 'Returned' : 'Mark as returned'"
            :disabled="!!returnTagTime(data.item, 'return_received')"
            @click="markReturnAsReceived(data)"
          >
            <b-icon icon="check" />
          </b-button>
          
          <!-- close/open return state -->
          <b-button
            class="mr-2"
            :variant="data.item.return_closed_at ? 'success' : 'outline-success'"
            size="sm"
            :title="data.item.return_closed_at ? 'Re-Open' : 'Close'"
            @click="toggleCloseReturn(data.item)"
          >
            <b-icon :icon="data.item.return_closed_at ? 'door-closed' : 'door-open-fill'" />
          </b-button>

          <!-- job's pack view -->
          <b-button
            variant="outline-secondary"
            size="sm"
            :to="`${$store.state.admin_base_url}/ui/packing/jobview?id=${data.item.jobid}&account=${data.item.account}`"
            target="_blank"
            title="Go to Job's pack overview."
            class="mr-2"
          >
            <b-icon icon="box-arrow-up-right" />
          </b-button>

          <!-- print parcel label (received) -->
          <b-button
            v-if="returnTagTime(data.item, 'return_received')"
            class="mr-2"
            variant="outline-secondary"
            size="sm"
            title="Print parcel label"
            :disabled="isLabelPrinting"
            @click="printParcelLabel(data.item)"
          >
            <b-icon v-if="!isLabelPrinting" icon="printer" />
            <b-icon v-else icon="arrow-clockwise" animation="spin" />
          </b-button>

          <!-- edit return comment -->
          <b-button
            v-if="!returnTagTime(data.item, 'return_closed_at')"
            :variant="data.item.return_comment ? 'outline-info' : 'outline-secondary'"
            class="mr-2"
            size="sm"
            title="View/Edit Comment"
            @click="editComment(data)"
          >
            <b-icon icon="info-circle-fill" />
          </b-button>

          <!-- cancel -->
          <b-button
            v-if="!returnTagTime(data.item, 'return_received')"
            variant="danger"
            size="sm"
            title="Cancel return"
            @click="cancelReturn(data)"
          >
            <b-icon icon="trash" />
          </b-button>

        </div>
      </template>

      <!-- Row Details Toggle --
      <template #cell(actions)="row">
        <b-button size="sm" @click="row.toggleDetails" class="">
          <b-icon icon="chevron-down" v-if="!row.detailsShowing"></b-icon>
          <b-icon icon="chevron-up" v-else></b-icon>
        </b-button>
      </template>-->

      <!-- Row Details --
      <template #row-details="row">
        <b-card>
          <b-row>
            <b-col sm="3" class="text-sm-right"><b>Warnings:</b></b-col>
            <b-col><div v-for="(warning, wkey) in row.item.fulldata_warnings" :key="`${row.item.id}-warning-${wkey}`">{{ warning.text }}</div></b-col>
          </b-row>
          <b-row>
            <b-col sm="3" class="text-sm-right"><b>Is Active:</b></b-col>
            <b-col>{{ row.item.isActive }}</b-col>
          </b-row>
        </b-card>
      </template>-->

    </b-table>

    <!-- table pagination -->
    <!--
    <b-row>
      <b-col col="12" class="text-right">
        <b-pagination
          class="d-inline-flex"
          v-model="table_filters.currentPage"
          :total-rows="table_settings.total_rows"
          :per-page="table_filters.perPage"
        >
        </b-pagination>
      </b-col>
    </b-row>
    -->

      <b-row>
        <b-col sm="6" md="6" lg="4" class="my-1">
          <b-form-group
            label="Per page"
            label-for="per-page-select"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-1"
          >
            <b-form-select
              id="per-page-select"
              v-model="table_filters.perPage"
              size="sm"
              :options="table_settings.pageOptions"
            ></b-form-select>
          </b-form-group>
        </b-col>

        <b-col sm="6" md="6" lg="4" offset-sm="0" offset-md="0" offset-lg="4" class="my-1">
          <b-pagination
            v-model="table_filters.currentPage"
            :total-rows="table_settings.total_rows"
            :per-page="table_filters.perPage"
            align="fill"
            size="sm"
          >
          </b-pagination>
        </b-col>
      </b-row>
    
  </div>
</template>


<script>
import { mapActions, mapGetters } from 'vuex';
//import Multiselect from '@/components/JobContent/Multiselect.vue';
//import CountryTags from '@/components/Tags/CountryTags.vue';

export default {
  name: 'JobReturnTable',
  components: {
    //Multiselect,
    //CountryTags,
  },

  props: {
    settings: {
      type: null,
      default: function () {
        return {}
      }
    },
    filters: {
      type: null,
      default: function () {
        return {}
      }
    }
  },

  data() {
    return {
      isLabelPrinting: false,
      returnDataEdit: {},
      table_settings: {
        isBusy: false,
        total_rows: 9999,
        pageOptions: [10, 15, 30], // { value: 100, text: "Show a lot" }
      },
      table_filters: { // Specify also here to make it responsive...
        currentPage: 1,
        perPage: 10,
        sortBy: 'return_created_at',
        sortDesc: true,
        search: '', // - since we wrote our own
        return_status: null,
        return_action: null,
        bounced_status: null,
        account: null,
        country: null,
      },
      // Table columns
      table_fields_template: [
        {
          key: 'jobid',
          label: 'Job Id',
          sortable: true,
          //tdClass:"text-primary cursor-pointer",  // stickyColumn: true, isRowHeader: true, variant: 'info',
        },
        {
          access: ['puadmin', 'superadmin'],
          key: 'account',
          label: 'Account',
          sortable: false,
          //tdClass:"text-primary cursor-pointer",  // stickyColumn: true, isRowHeader: true, variant: 'info',
        },
        {
          key: 'return_created_at',
          label: 'Created At',
          sortable: true,
        },
        {
          key: 'return_status',
          label: 'Status',
          sortable: false,
        },
        {
          key: 'name',
          label: 'Name',
          sortable: true,
          thClass: 'd-none d-md-table-cell',
          tdClass: 'd-none d-md-table-cell'
        },
        {
          key: 'country',
          label: 'Country',
          sortable: true,
          thClass: 'd-none d-md-table-cell',
          tdClass: 'd-none d-md-table-cell'
        },
        {
          key: 'customer_actions',
          label: 'Requested Action',
          sortable: false,
        },
        {
          access: ['puadmin', 'superadmin'],
          key: 'actions',
          label: 'Admin Actions',
          sortable: false,
        },
      ],

      table_return_status_filters: [
        {id: null, text: 'Select to filter...' },
        {id: 'pending', text: 'Pending'},
        {id: 'returned', text: 'Returned'},
        {id: 'closed', text: 'Closed'},
      ],

      table_return_actions_filters: [
        {id: null, text: 'Select to filter...' },
        {id: 'resend', text: 'Send again'},
        {id: 'restock', text: 'Return'},
        {id: 'undecided', text: 'Undecided'},
      ],
    }
  },

  async created() {
    let filters = this.getPageSettings( this.$route.name ) // from saved settings
    this.table_settings = Object.assign({}, this.table_settings, this.settings) // default <- props
    this.table_filters = Object.assign({}, this.table_filters, filters, this.filters) // default <- saved <- props
    
    this.savePageSettings({id: this.$route.name, settings: this.table_filters }); // Save it back to update from incoming forced args
  },

  computed: {
    ...mapGetters([
      'getPageSettings',
      'timestampAsDate',
      'availableDeliveryCountries',
      'countryByCode',
    ]),
    ...mapGetters('user', [
      'currentUser',
      'userAccounts',
      'userAccountName',
      'userHasAnyOfRoles',
    ]),
    ...mapGetters('job', [
      'printerapiStatusName'
    ]),

    table_fields() {
      let fields = this.table_fields_template;
      if ( ! this.userHasAnyOfRoles(['superadmin']) ) {
        fields = fields.filter(x => ! x.access || this.userHasAnyOfRoles(x.access));
      }
      return fields;
    },
  },

  methods: {
    ...mapActions([
      'savePageSettings',
    ]),

    ...mapActions('job', [
      'queryJobsPromise',
      'updateReturnJobPromise',
    ]),

    refreshTableData() {
      this.$refs.table.refresh()
    },

    tabledataProvider(ctx){
      const queryObject = {
        'account': this.table_filters.account?.account ?? null, // multiselect does only return full selected object
        'status': 'completed', // this.table_filters.status ?? null,
        'marked_for_return': true,
        'return_status': this.table_filters.return_status ?? null,
        'return_action': this.table_filters.return_action ?? null,
        'bounced_status': this.table_filters.bounced_status ?? null,
        'search': this.table_filters.search ?? null,
        'country': this.table_filters.country ?? null,
        'return_from_date': this.table_filters.return_from_date ?? null,
        'return_to_date': this.table_filters.return_to_date ?? null,
        ...ctx,
      };

      // Save filters, instead of with watcher
      this.savePageSettings({id: this.$route.name, settings: this.table_filters });

      return this.queryJobsPromise(queryObject)
        .then(response => {
          this.table_settings.total_rows = response?.data?.total ?? 0;
          return response?.data?.items ?? [];
        }).catch(() => {
          this.$toastIt('Failed to load data!', {variant: 'danger', autoHideDelay: 2000});
          return [];
        });
    },

    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (item?.warnings_active_public) return 'table-warning'
      if (item?.deleted) return 'table-danger'
    },

    // Find out a return status name... based on something unknown so far...
    returnStatusName(item) {
      const is_created = item?.return && item.return.filter( x => x.id == 'marked_for_return' ).length;
      const is_returned = item?.return && item.return.filter( x => x.id == 'return_received' ).length;
      const is_closed = item?.return && !!item.return_closed_at;
      if ( is_closed ) return "Closed";
      if ( is_created && ! is_returned ) return "Pending";
      if ( is_created && is_returned ) return "Returned";
      return "...";
    },
    // Find out a return status name... based on something unknown so far...
    returnTagTime(item, tagname) {
      const tag = item?.return.find( x => x.id == tagname );
      if ( tag?.at ) return tag.at; //return this.$moment( new Date(tag.at * 1000) ).format("YYYY-m-d HH:ii");
      return undefined;
    },
    markReturnAsReceived( data ) {
      const item = data.item;
      this.$confirmIt(`Do you want to mark return of order "${item.jobid}" as received?`)
      .then(value => {
        if (value) {
          this.updateReturnJobPromise({id: item.id, data: { return_received: true } })
          .then((result) => {
            if (result.data.status == 'ok') {
              const message = result.data.message || 'Job marked with return!';
              this.$toastIt(message, {variant: 'info', autoHideDelay: 2000});
              // DO NOT refresh table - backend is SLOOOOW and messes up everything as usual with the sync...
              //this.refreshTableData();
              // - INSTEAD: Force new data into the current table item. Works here because it's always an array from the get-go!
              item.return.push({id:'return_received', at: Date.now() });
              data.item = item;
              // also print a parcel label
              this.printParcelLabel(item);
            }
            if (result.data.status !== 'ok') {
              const message = result.data.message || 'Could not mark job for return!';
              this.$toastIt(message, {variant: 'danger', autoHideDelay: 2000});
            }
          });
        }
      });
    },

    cancelReturn( data ) {
      const item = data.item;
      this.$confirmIt(`Do you want to cancel return of order "${item.jobid}"?`)
      .then(value => {
        if (value) {
          this.updateReturnJobPromise({id: item.id, data: { marked_for_return: false, customer_return: false, bounced_return: false, return_received: false } })
          .then((result) => {
            if (result.data.status == 'ok') {
              const message = result.data.message || 'Return of Job cancelled!';
              this.$toastIt(message, {variant: 'info', autoHideDelay: 2000});
              this.refreshTableData();
            }
            if (result.data.status !== 'ok') {
              const message = result.data.message || 'Could not update return status!';
              this.$toastIt(message, {variant: 'danger', autoHideDelay: 2000});
            }
          });
        }
      });
    },   
    
    markAction(item, state) {
      // Implement simple switchable state
      // - when null go value : when value is same as already set go null, else go value
      const switchedState = item?.return_action === null ? state : (item?.return_action === state ? null : state);

      this.updateReturnJobPromise({
        id: item.id,
        data: {
          return_action: switchedState
        }
      })
      .then((result) => {
        if (result.data.status == 'ok') {
          const message = result.data.message || 'Return updated!';
          this.$toastIt(message, {variant: 'info', autoHideDelay: 2000});
          this.refreshTableData();
        }
        if (result.data.status !== 'ok') {
          const message = result.data.message || 'Could not update return!';
          this.$toastIt(message, {variant: 'danger', autoHideDelay: 2000});
        }
      });
    },

    /**
     * Toggle Return Completed At
     */
    async toggleCloseReturn(item) {
      // - first confirm
      const answer = await this.$confirmIt(`Do you want to ${item.return_closed_at ? 're-open' : 'close'} return for "${item.jobid}"?`)
      if (! answer) return;
      // - then update
      this.updateReturnJobPromise({
        id: item.id,
        data: {
          return_closed_at: !item.return_closed_at
        }
      })
      .then((result) => {
        if (result.data.status == 'ok') {
          const message = result.data.message || 'Return updated!';
          this.$toastIt(message, {variant: 'info', autoHideDelay: 2000});
          this.refreshTableData();
        }
        if (result.data.status !== 'ok') {
          const message = result.data.message || 'Could not update return!';
          this.$toastIt(message, {variant: 'danger', autoHideDelay: 2000});
        }
      });
    },

    currentTimestamp() {
      return Math.floor(Date.now() / 1000);
    },

    // PRINT LABEL FOR PARCELS
    printParcelLabel(data) {
      // Get label url from local storage (manually set)
      const returnParcelUrl = window.localStorage.getItem('returnParcelUrl');
      if (! returnParcelUrl) {
        this.$toastIt('No returnParcelUrl in local storage!', {variant: 'danger', autoHideDelay: 2000});
        return;
      }
      
      // get params from data (item/row)
      const { jobid, account, name } = data;
      const returned_at = this.returnTagTime(data, 'return_received');

      // build query params
      const params = new URLSearchParams();
      params.append('account', account);
      params.append('jobid', jobid);
      params.append('name', name);
      params.append('returned_at', returned_at);
      
      // build url
      const queryString = params.toString();
      const url = `${returnParcelUrl}?${queryString}`;

      /* TEST to open an unsecure self-closing window that prints */
      // try {
      //   const openedWindow = window.open(url, '_blank');
      //   setTimeout(function() { openedWindow.close(); }, 200);
      // } catch (error) {
      //   this.$toastIt('Failed to open print tab!', {variant: 'danger', autoHideDelay: 2000});
      // }
      /* END TEST WORKAROUND */

      this.isLabelPrinting = true;
      
      // execute fetch
      fetch(url, {
        method: 'GET',
        // mode: 'no-cors', // no-cors might be OK since we do not read the response body anyway
        // targetAddressSpace: "private",
      })
      .then(() => {
        this.$toastIt('Label queued for print...', {variant: 'info'});
      })
      .catch(error => {
        console.error(error)
        this.$toastIt('Could not print label!', {variant: 'danger', autoHideDelay: 2000});
      })
      .finally(() => {
        this.isLabelPrinting = false;
      })
    },

    editComment(data) {
      this.returnDataEdit = Object.assign({}, (data?.item ?? {}));
      this.$bvModal.show('return-comment-modal');
    },

    viewComment(data) {
      this.returnDataEdit = Object.assign({}, (data?.item ?? {}));
      this.$bvModal.show('return-comment-modal-client');
    },

    saveComment() {
      // get params from data (item/row)
      const { id, return_comment } = this.returnDataEdit;
      this.updateReturnJobPromise({id: id, data: {return_comment: return_comment}})
        .then((result) => {
          if (result.data.status == 'ok') {
            const message = result.data.message || 'Return comment updated!';
            this.$toastIt(message, {variant: 'info', autoHideDelay: 2000});
            this.refreshTableData();
          }
          if (result.data.status !== 'ok') {
            const message = result.data.message || 'Could not update!';
            this.$toastIt(message, {variant: 'danger', autoHideDelay: 2000});
          }
        });
    }
/*
    flags(id) {
      console.log(id)
      return '<svg viewBox="0 0 640 480"><path fill="#066aa7" d="M0 0h640v480H0z"/><path fill="#fecc00" d="M0 192h640v96H0z"/><path fill="#fecc00" d="M176 0h96v480h-96z"/></svg>';
    }
    */
  },
}
</script>

<style lang="scss">
svg { height: 1em; width: auto; }
</style>